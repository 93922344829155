:root {
    --white: #FFF;

    --background: #F7F7F7;
    --primary: #52ADA1;
    --secondary: #85C5BC;
    --tertiary: #AAA;
    --quaternary: #EEE;

    --text-primary: #52ADA1;
    --text-secondary: #929292;
    --text-tertiary: #A7A7A7;

    --standard-border: 1px solid var(--quaternary);
    --subtle-border: 1px solid var(--quaternary);
    --standard-shadow: 0px 0px 5px #AAA;
    --subtle-shadow: 0px 0px 5px #EEE;

    --icon-size: 100px;
    --icon-size-lite: 60px;
    --font-family: "Helvetica Neue";

    /* Fonts */
    --font-size-hero-title: 25px;
    --font-weight-hero-title: 800;
    --font-color-hero-title: var(--text-primary);

    --font-size-hero-subtitle: 18px;
    --font-weight-hero-subtitle: 500;
    --font-color-hero-subtitle: var(--text-secondary);

    --font-size-app: 18px;
    --font-weight-app: 600;
    --font-color-app: var(--text-primary);

    --font-size-category: 16px;
    --font-weight-category: 500;
    --font-color-category: var(--text-secondary);

    --font-size-section-title: 20px;
    --font-weight-section-title: 500;
    --font-color-section-title: var(--text-primary);

    --font-size-section-subtitle: 16px;
    --font-weight-section-subtitle: 350;
    --font-color-section-subtitle: var(--text-secondary);

    --font-size-section-h3: 13px;
    --font-weight-section-h3: 700;
    --font-color-section-h3: var(--text-secondary);

    --font-size-description: 16px;
    --font-weight-description: 350;
    --font-color-description: var(--text-secondary);

    --large: 48px;
    --standard: 20px;
    --small: 8px;
    --tiny: 2px;
}

body {
  background-color: var(--background);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Container */
.rs-container {

}

hr {
  margin-top: var(--standard);
  margin-bottom: var(--standard);
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

.content {
  margin-left: auto;
  margin-right: auto;

  width: min(900px, 100%);
  text-align: left;
}

.row {
  display: flex;
}

.row > div:first-child {
  margin-left: 0px;
}

.row > div {
  margin-left: var(--standard);
}

.list {
  padding-left: 0px;
  list-style-type: none;
}

.list > li[active="true"]:hover {
  background: var(--primary);
}
.list > li[active="true"] {
  background: var(--secondary);
}
.list > li[active="true"] > a {
  color: white;
}

.list > li:first-child {
  border-top: none;
}


.list > li:hover {
  background: rgba(0,0,0,0.04);
}

.list > li > a {
  text-decoration: none;
  color: var(--text-primary);
}

.list > li {
  background: rgba(0,0,0,0.02);
  border-radius: var(--small);
  text-decoration: none;
  padding: var(--small);
  margin-top: var(--small);
  margin-bottom: var(--small);
}

.service {
  width: 75%;
  padding-left: var(--standard);
  border-left: var(--subtle-border);
}

.rs-content {
  background-color: white;
}

.rs-row {
  height: 300px;
}
.rs-row > div {
  height: 100%;
  margin-bottom: var(--standard);
}

.hero-block {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  border-bottom: var(--standard-border);
  box-shadow: var(--subtle-shadow);
  border-radius: var(--standard);
  padding: var(--standard);
  margin: var(--small);
}
.hero-block > a:hover {
  text-decoration: none;
}
.hero-block > a {
  margin: auto;
}
.hero-block > a > img {
  border-radius: var(--small);
}

.person-image {
  width: 200px;
}
.person-image > img {
  width: 200px;
  border-radius: var(--small);
}

.person-info {
  margin-left: var(--standard);
  margin-top: auto;
  margin-bottom: auto;
}

/* Navbar */
.rs-header {
  background-color: var(--white);
  border-bottom: var(--standard-border);
  box-shadow: var(--standard-shadow);
  padding: var(--tiny);
  margin-bottom: var(--standard);
  height: 105px;
}
.rs-navbar-header {
  height: inherit;
}
.rs-navbar.rs-navbar-default {
  display: flex;
  background-color: initial;
}
.rs-navbar-nav.rs-navbar-right {
  height: initial;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: auto;
}
.rs-header .rs-nav > ul > .rs-nav-item > .rs-nav-item-content {
  color: var(--primary);
  padding: var(--small);
  margin-left: var(--small);
  height: auto;
  border-radius: var(--tiny);
}
.rs-header .rs-nav > ul > .rs-nav-item > .rs-nav-item-content:hover {
  color: var(--white);
  background-color: var(--secondary) !important;
}

/* Logo */
.app-logo-bar {
  display: flex;
  text-align: left;
  text-decoration: none;
}
.app-logo-bar:hover {
  text-decoration: none;
}

.app-logo {

}
.app-logo > img {
  height: 60px;
}
.app-logo > span {
  color: var(--white);
  font-family: "Apple Chancery";
  font-size: 38px;
}

.app-title {
  margin-left: var(--standard);
  margin-top: auto;
}

/* Hero */
.hero {
  padding: var(--standard);
}

.hero-title {
  font-family: var(--font-family);
  font-weight: var(--font-weight-hero-title);
  font-size: var(--font-size-hero-title);
  color: var(--font-color-hero-title);
}

.hero-subtitle {
  font-family: var(--font-family);
  font-weight: var(--font-weight-hero-subtitle);
  font-size: var(--font-size-hero-subtitle);
  color: var(--font-color-hero-subtitle);
}

/* App */
.app-header-lite {
  display: flex;
  padding: var(--small);
  border-bottom:  var(--standard-border);
  border-radius:  var(--small);
  text-decoration: none;
}

.app-header-lite:hover {
  background-color: var(--background);
  text-decoration: none;
}

.app-header-lite:last-of-type {
  border-bottom: initial;
}

.app-header-lite > .app-icon {
  display: flex;
  width: var(--icon-size-lite);
}

.app-header-lite > .app-icon > img {
  margin: auto;
  width: var(--icon-size-lite);
  height: var(--icon-size-lite);
}

.app-header-lite > .app-info {
  display: table-column;
  text-align: left;
  margin-left: var(--standard);
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
  text-decoration: none;
}

.app-lite-title {
  display: flex;
}

.app-lite-title > .app-title {
  display: table-row;
  font-family: var(--font-family);
  font-weight: var(--font-weight-app);
  color: var(--font-color-app);
  font-size: var(--font-size-app);
}

.app-header-lite .app-description {
  display: table-row;
  font-family: var(--font-family);
  font-weight: var(--font-weight-description);
  color: var(--font-color-description);
  font-size: var(--font-size-description);
}

.app-lite-title > .app-rating {
  display: table-row;
  font-size: 12px;
  margin-left: var(--standard);
}

.app-lite-title > .app-rating > .rs-rate > .rs-rate-character {
  margin: 3px 5px 3px 0;
}

.app-header-lite > .app-actions {
  display: flex;
}

/* App Header: Full */
.app-header {
  display: flex;
  padding: var(--standard);
  margin-bottom: var(--standard);
  border-bottom:  var(--standard-border);
}

.content {
  background-color: var(--white);
  border-radius: var(--standard);
  padding: var(--standard);
}

.app-header > .app-icon {
  display: flex;
  width: var(--icon-size);
}
.app-header > .app-icon > img {
  margin: auto;
  width: var(--icon-size);
  height: var(--icon-size);
}

.app-header > .app-info {
  display: table-column;
  text-align: left;
  margin-left: var(--standard);
  margin-top: auto;
  margin-bottom: auto;
  margin-right: auto;
}

.app-header > .app-info > .app-title {
  display: table-row;
  font-family: var(--font-family);
  font-weight: var(--font-weight-app);
  color: var(--font-color-app);
  font-size: var(--font-size-app);
}

.app-header > .app-info > .app-category {
  display: table-row;

  font-family: var(--font-family);
  font-weight: var(--font-weight-category);
  font-size: var(--font-size-category);
  color: var(--font-color-category);
}

.app-header > .app-info > .app-rating {
  display: table-row;
}

.app-header > .app-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mas-download {
  width: 156px;
  height: 40px;
  background-image: url("mas.svg");
  background-repeat: no-repeat;
  margin: auto;
}

.download-btn {
  display: flex;
  width: 156px;
  height: 40px;
  background-color: var(--white);
  border: 1px solid var(--primary);
  border-radius: 8px;
  margin: auto;
  color: var(--text-primary);
}

.download-btn:hover {
  text-decoration: none;
  box-shadow: var(--standard-shadow);
  color: var(--text-primary);
}

.download-btn:active {
  text-decoration: none;
  box-shadow: var(--standard-shadow);
  background-color: var(--secondary);
  color: var(--white);
}

.download-btn > span {
  margin: auto;
}

/* Section */
.app-section {
  text-align: left;
  padding: var(--standard);
}

h1 {
  line-height: 1;
  font-family: var(--font-family);
  font-weight: var(--font-weight-section-title);
  font-size: var(--font-size-section-title);
  color: var(--font-color-section-title);
  margin-bottom: var(--small);
}

h2 {
  line-height: 1;
  font-weight: var(--font-weight-section-subtitle);
  font-size: var(--font-size-section-subtitle);
  color: var(--font-color-section-subtitle);
  margin-bottom: var(--small);
}

h3 {
  line-height: 1;
  font-weight: var(--font-weight-section-h3);
  font-size: var(--font-size-section-h3);
  color: var(--font-color-section-h3);
  margin-bottom: var(--small);
}

h4 {
  line-height: 1;
  font-weight: var(--font-weight-section-h3);
  font-size: var(--font-size-section-h3);
  color: var(--tertiary);
  margin-bottom: var(--small);
}

p {
  margin-bottom: var(--standard);
}

p:last-of-type {
  margin-bottom: 0px;
}

.app-carousel {
  /* height: 800px; */
}

.rs-carousel {
  height: initial;
  border-radius: var(--standard);
  border: var(--subtle-border);
  background: var(--white);
}

.rs-carousel-content {
  border-radius: var(--standard);
  overflow: hidden;
}

/* Privacy */
.privacy {
  text-align: left;
}

/* Support */
.app-contact > form {
  text-align: left
}

.app-question {

}

.app-choices {
  margin-top: var(--standard);
  display: inline-block;
  width: auto;
}

.app-choice-app {
  text-align: left;
}

.app-choice {
  margin-left: auto;
  margin-right: auto;

  padding: var(--small) var(--large) var(--small) var(--large);
  border-bottom: var(--standard-border);
}

.app-choice-icon {
  display: inline-block;
  margin-right: var(--small);
  height: 20px;
}

.app-choice-icon > img {
  height: 20px;
}

.other {
  margin-top: var(--standard);
  color: var(--white);
  font-family: var(--font-family);
  font-size: 12px;
}

.app-choice:last-child {
  border-bottom: none;
}
